var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"de-duplication",class:{ navigation: _vm.NAVIGATION_ENABLED }},[_c(_setup.Link,{staticClass:"go-back-button",attrs:{"type":"neutral","size":"sm","left-icon":"arrow-narrow-left","label":_setup.getGoBackText,"underline":true},on:{"click":_vm.goBack}}),_c('div',{staticClass:"de-dupliacation-content"},[_c('div',{staticClass:"left-col"},[_c('h3',{staticClass:"section-header text-xl semibold"},[_vm._v(" "+_vm._s(_setup.i18n.t("page.deduplication.profileToIdentificate"))+" ")]),(_vm.currentProfileToDeduplicate)?_c(_setup.IdentificationWorkerColumn,{attrs:{"profileToDeDuplicate":_vm.currentProfileToDeduplicate,"fields":[
                    {
                        label: _setup.i18n.t('form.email').toString(),
                        value: _vm.currentProfileToDeduplicate.email || '-',
                        isSameValue: false,
                    },
                    {
                        label: _setup.i18n.t('form.phone').toString(),
                        value: _vm.convertPhone(_vm.currentProfileToDeduplicate.phone) || '-',
                        isSameValue: false,
                    },
                ]},scopedSlots:_vm._u([{key:"actionContainer",fn:function(){return [_c('div',{staticClass:"action-button-container"},[_c(_setup.Button,{attrs:{"disabled":_vm.disabler.addToMyAts,"size":"lg","label":_setup.i18n.t('page.deduplication.addToMyResumeLibrary').toString(),"full-width":true},on:{"click":function($event){return _setup.emit('rejectClick')}}})],1)]},proxy:true}],null,false,1928527600)}):_vm._e()],1),_c('div',{staticClass:"right-col"},[(_vm.identificationResults.length > 0)?_c('h3',{staticClass:"section-header text-xl semibold"},[_vm._v(" "+_vm._s(_setup.i18n.t("page.deduplication.nbDuplicatedFound." + (_vm.identificationResults.length <= 1 ? "singular" : "plural"), { nb: _vm.identificationResults.length, }))+" ")]):_vm._e(),(!_vm.showIdentificationResults)?_c('div',{staticClass:"right-col-content"},[(_vm.loading)?_c('div',{staticClass:"lottie-loader"},[_c(_setup.LottieFile,{attrs:{"jsonLottieFile":_setup.loaderSearchDuplicatedLottieAnnimation}}),_c('p',{staticClass:"text-lg semibold"},[_vm._v(_vm._s(_setup.i18n.t("page.deduplication.loadingDuplicated")))])],1):_vm._e(),_vm._l((_vm.identificationResults),function(duplicatedProfile,duplicatedIndex){return (!_vm.loading)?_c('div',{key:duplicatedIndex,on:{"click":function($event){$event.stopPropagation();return _vm.onShowDuplicatedDetails(duplicatedIndex)}}},[_c(_setup.IdentificationWorkerCard,{attrs:{"displayOrigin":true,"displayValidity":true,"fields":[
                            {
                                label: 'email',
                                value: duplicatedProfile.email || '-',
                                isSameValue: duplicatedProfile.email === _vm.currentProfileToDeduplicate?.email,
                            },
                            {
                                label: 'phone',
                                value: _vm.convertPhone(duplicatedProfile.phone),
                                isSameValue: _vm.convertPhone(duplicatedProfile.phone) === _vm.convertPhone(_vm.currentProfileToDeduplicate?.phone ?? ''),
                            },
                        ],"identity":{
                            firstname: duplicatedProfile.firstname,
                            lastname: duplicatedProfile.lastname,
                            isValid: _vm.isSameFirstnameAndLastname(_vm.currentProfileToDeduplicate, duplicatedProfile),
                            databaseOrigin: duplicatedProfile.databaseOrigin,
                            picture: duplicatedProfile.picture,
                            cv: duplicatedProfile.cv,
                        },"mode":"light","agencies":duplicatedProfile.agencies,"jobs":duplicatedProfile.jobs},scopedSlots:_vm._u([{key:"action-container",fn:function(){return [_c('div',{on:{"click":function($event){$event.stopPropagation();return (() => _setup.onMergeClick(duplicatedIndex)).apply(null, arguments)}}},[_c(_setup.Button,{attrs:{"label":_setup.i18n.t('page.deduplication.merge').toString(),"left-icon":"git-merge","size":"lg","full-width":true}})],1)]},proxy:true}],null,true)})],1):_vm._e()})],2):_vm._e(),(_vm.showIdentificationResults && _vm.currentIdentificationResult)?_c(_setup.IdentificationWorkerColumn,{attrs:{"profileToDeDuplicate":_vm.currentIdentificationResult,"fields":_vm.currentIdentificationResultFields,"verifyFields":true,"displayOrigin":true,"isValidIdentity":_vm.isSameFirstnameAndLastname(_vm.currentProfileToDeduplicate, _vm.currentIdentificationResult),"card-can-be-close":true},on:{"close-card":function($event){return _vm.setShowIdentificationResults(false)}},scopedSlots:_vm._u([{key:"actionContainer",fn:function(){return [_c(_setup.Button,{attrs:{"label":_setup.i18n.t('page.deduplication.merge').toString(),"left-icon":"git-merge","size":"lg","full-width":true,"disabled":_vm.disabler.addToMyAts},on:{"click":function($event){return _setup.emit('mergeClick')}}})]},proxy:true}],null,false,2584486315)}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }