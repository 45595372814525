import { render, staticRenderFns } from "./IdentificationWorkerCard.vue?vue&type=template&id=08285e28&scoped=true&"
import script from "./IdentificationWorkerCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./IdentificationWorkerCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./IdentificationWorkerCard.vue?vue&type=style&index=0&id=08285e28&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08285e28",
  null
  
)

export default component.exports